import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container, Card, Typography, Tab, Tabs, Box, Grid, Button,
} from "@mui/material";
import { Loading, Pagination as Paginate, PurchaseItem, SearchFeature, Spinner, } from 'components';
import PencilLoader from 'components/atoms/PencilLoader';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const PurchasePage = ({ all, process, send, done }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token')));
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const query = useQuery();
  const page = query.get('page') || 1;
  const searchQuery = query.get('searchQuery');



  return (
    <Container>
      <Card sx={{ padding: 2, mt: 1 }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, px: 0 }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="All" {...a11yProps(0)} sx={{ fontSize: 12, }} />
              {page && !searchQuery && (
                <Tab label="Proses" {...a11yProps(1)} sx={{ fontSize: 12, }} />
              )}
              {page && !searchQuery && (
                <Tab label="Kirim" {...a11yProps(2)} sx={{ fontSize: 12, }} />
              )}
              {page && !searchQuery && (
                <Tab label="Done" {...a11yProps(3)} sx={{ fontSize: 12, }} />
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {page && (
              <>
                {
                  all && all.data.length > 0 ? (
                    <>
                      {/* <Container maxWidth="xs" sx={{ mb: 2, minWidth: 200, }}>
                        <SearchFeature
                          border="1px solid black"
                          color="black"
                          searchSubject="purchase"
                        />
                      </Container > */}
                      <PurchaseItem data={all} />
                    </>
                  ) : all && !all.data.length > 0 ? (
                    <Typography variant='h6' align='center'>Data tidak ditemukan</Typography>
                  ) : (
                    <PencilLoader />
                  )
                }
                <Paginate page={page} status={""} />
              </>
            )}
          </TabPanel>
          {page && !searchQuery && (
            <>
              <TabPanel value={value} index={1}>
                {
                  process && process.data.length > 0 ? (
                    <PurchaseItem data={process} />
                  ) : process && !process.data.length > 0 ? (
                    <Typography variant='h6' align='center'>Data tidak ditemukan</Typography>
                  ) : (
                    <PencilLoader />
                  )
                }
                <Paginate page={page} status={"Proses"} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                {
                  send && send.data.length > 0 ? (
                    <PurchaseItem data={send} />
                  ) : send && !send.data.length > 0 ? (
                    <Typography variant='h6' align='center'>Data tidak ditemukan</Typography>
                  ) : (
                    <PencilLoader />
                  )
                }
                <Paginate page={page} status={"Kirim"} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                {
                  done && done.data.length > 0 ? (
                    <PurchaseItem data={done} />
                  ) : done && !done.data.length > 0 ? (
                    <Typography variant='h6' align='center'>Data tidak ditemukan</Typography>
                  ) : (
                    <PencilLoader />
                  )
                }

                <Paginate page={page} status={"Selesai"} />
              </TabPanel>
            </>
          )}
        </Box>
      </Card>
    </Container>
  );

};

export default PurchasePage;
