import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, CircularProgress } from "@mui/material";

import { getAllBooking, } from "store/actions/booking";

export default function Booking() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dataBookingReducerAll } = useSelector(
    (state) => state.bookingReducer
  );
  const [isLoading, setIsLoading] = useState(true);
  const [dataBooking, setDataBooking] = useState([]);
  const user = useState(
    JSON.parse(localStorage.getItem("sb-wnpukijoybwfgrpearge-auth-token"))
  );
  const userId = user[0]?.user?.id;

  useEffect(() => {
    dispatch(getAllBooking(userId, "", 1, ""));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setDataBooking(dataBookingReducerAll?.data?.data);
  }, [dataBookingReducerAll]);
  console.log('dataBooking====>>', dataBooking);

  const onDetail = (id) => {
    history.push(`bookingDetail/${id}`);
  };

  return (
    <>
      {isLoading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <TableContainer component={Paper} style={{ marginTop: 10 }}>
            <Table sx={{ minWidth: 50 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Invoice</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Tools</TableCell>
                </TableRow>
              </TableHead>
              {
                dataBooking?.map((row) => (
                  <TableBody key={row.id}>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row.invoice}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.items.map((item, index) => (
                          <span key={index}>
                            {item.title}
                            {index < row.items.length - 1 && ', '}
                          </span>
                        ))}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          color:
                            row.payments_status === 'Ditolak' ? 'red' :
                              row.payments_status === 'Dikirim' ? 'green' : 'blue',
                        }}
                      >
                        {row.payments_status}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          variant="outlined"
                          onClick={() => onDetail(row.id)}
                        >
                          Detail
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              }

            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
