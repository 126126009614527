
import React, { useState, lazy, Suspense } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { addCart, clearCart, updateCart } from 'store/actions/cart';
import { Box, Card, CardContent, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import convertToRupiah from "utils/formatCurrency";
import { Button, Loading, Typography } from "components/atoms";
import PencilLoader from "components/atoms/PencilLoader";
const LazyCardMedia = lazy(() => import('@mui/material/CardMedia'));

const CardItem = (props) => {
    const { key, id, image, title, price, unit, } = props;

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token')));

    const dispatch = useDispatch();
    const history = useHistory();

    const { cartItems } = useSelector((state) => state.cartReducer);

    const initialState = { qty: 0, };
    const [form, setForm] = useState(initialState);
    const { qty, } = form;

    const incrementQuantity = () => {
        setForm({ ...form, qty: qty + 1 });
    };

    const decrementQuantity = () => {
        if (qty > 1) {
            setForm({ ...form, qty: qty - 1 });
        }
    };

    const addToCart = () => {
        let item = cartItems.find((item) => item.id === id);
        if (!item) {
            if (qty > 0) {
                dispatch(addCart({ id, title, qty, subtotal: price * (qty) }))
                setForm({ ...initialState, qty: 0 });
            }
        } else {
            let sumQty = parseInt(item.qty) + parseInt(form.qty);
            dispatch(updateCart({ ...item, qty: sumQty, subtotal: sumQty * price }));
            setForm({ ...initialState, qty: 0 });
        }
    };

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };
    const itemDetail = (id) => {
        history.push(`/item/${id}`);
    }


    return (
        <Box component="form" noValidate onSubmit={addToCart} >
            <Card sx={{ minWidth: 100, maxWidth: 200, maxHeight: 320, height: 290, mr: 2, mb: 4, boxShadow: 5 }} key={key}  >

                <Suspense fallback={<div><PencilLoader /></div>}>
                    {
                        user?.user
                            ?
                            <LazyCardMedia onClick={() => itemDetail(id)} component="img" height="120" image={image} />
                            :
                            <LazyCardMedia component="img" height="120" image={image} />
                    }
                </Suspense>
                <CardContent>
                    <Typography gutterBottom variant="body" component="div" title={title} />
                    <Typography variant="body2" title={`${convertToRupiah(price)} / ${unit}`} />
                    <Grid container style={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between' }}>
                        <Grid item xs={12}>
                            <TextField onChange={onChange} value={id} name="id" required fullWidth style={{ display: "none" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={onChange} value={title} name="title" required fullWidth style={{ display: "none" }} />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 3 }}>
                            <TextField label="Qty" name="qty" type="number" value={qty} onChange={onChange}
                                size="small"
                                InputProps={{
                                    endAdornment: (<InputAdornment position="end">
                                        <IconButton
                                            aria-label="decrement quantity"
                                            onClick={decrementQuantity}
                                            edge="end"
                                        >
                                            <RemoveIcon
                                                fontSize="small"
                                            />
                                        </IconButton>
                                        <IconButton
                                            aria-label="increment quantity"
                                            onClick={incrementQuantity}
                                            edge="end"
                                        >
                                            <AddIcon
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                    inputProps: { min: 1, step: 1 }
                                }} />
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: 10, }}>
                            {user?.user ? (
                                <Button variant="outlined" color="secondary" fontSize="small" title={"Detail"} onClick={() => itemDetail(id)} />
                            ) : (
                                <Button variant="outlined" disabled color="secondary" fontSize="small" title={"Detail"} onClick={() => itemDetail(id)} />
                            )}
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right", marginTop: 10, }}>
                            {user?.user ? (
                                <Button onClick={addToCart} variant="contained" color="secondary" title={(<ShoppingCartCheckoutIcon fontSize="small" style={{ color: "white" }} />)} />
                            ) : (
                                <Button onClick={addToCart} disabled variant="contained" color="secondary" title={(<ShoppingCartCheckoutIcon fontSize="small" style={{ color: "white" }} />)} />

                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card >
        </Box >
    )
}

export default CardItem
