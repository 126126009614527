import React from 'react'

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Auth from "pages/Auth/Auth";
import AuthVerify from "pages/Auth/AuthVerify";
import ResetPassword from "pages/Auth/ResetPassword";
import RecoverAccount from "pages/Auth/RecoverAccount";
import NewPassword from "pages/Auth/NewPassword";

import viewCategory from "pages/Admin/Category/viewCategory";
import viewItem from "pages/Admin/Item/viewItem";
import createCategory from "pages/Admin/Category/createCategory";
import createItem from "pages/Admin/Item/createItem";
import imageItem from "pages/Admin/Item/imageItem";
import detailItem from "pages/Admin/Item/detailItem";
import createFeature from "pages/Admin/Item/createFeature";
import createActivity from "pages/Admin/Item/createActivity";
import viewBooking from "pages/Admin/Booking/viewBooking";
import bookingDetail from "pages/Admin/Booking/bookingDetail";

import NotFound from "pages/404";
import Purchase from 'pages/Purchase';
import Dashboard from 'pages/Dashboard';
import ItemDetail from 'components/templates/ItemDetail';
import Message from 'pages/Message';
import { PurchaseItemDetail } from 'components';
import User from 'pages/User';
import MobilePurchase from 'components/molecules/MobilePurchase';
import UserMobile from 'pages/UseMobile';
import LeafletMobile from 'components/molecules/LeafletMobile';
import LeafletMobileAccount from 'components/molecules/LeafletMobileAccount';

const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL,
});

const Routes = () => {
    const user = JSON.parse(localStorage.getItem("sb-wnpukijoybwfgrpearge-auth-token"));
    return (
        <>
            <Router history={history} basename={process.env.PUBLIC_URL}>
                <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route path="/mobile/purchase/id/:id/email/:email" exact component={MobilePurchase} />
                    <Route path="/mobile/user/account/:currentId?" component={UserMobile} />
                    <Route path="/mobile/user/map/:email?" component={LeafletMobileAccount} />
                    <Route path="/search" exact component={Dashboard} />
                    <Route path="/item/:id" exact component={ItemDetail} />
                    <Route path="/purchase" exact component={Purchase} />
                    <Route path="/purchase/search" exact component={Purchase} />
                    <Route path="/purchase/:id" exact component={PurchaseItemDetail} />
                    <Route path="/message" exact component={Message} />

                    <Route path="/auth" exact component={() => (!user ? <Auth /> : <Redirect to="/" />)} />
                    <Route path="/authVerify/:token" component={AuthVerify} />
                    <Route path="/resetPassword" exact component={ResetPassword} />
                    <Route path="/recoverAccount" exact component={RecoverAccount} />
                    <Route path="/newPassword/:token" exact component={NewPassword} />
                    {/* user */}
                    <Route path="/user/account/:currentId?" component={User} />
                    {/* admin */}
                    <Route path="/adminwol" exact component={() => <Redirect to="/adminwol/viewBooking" />} />
                    <Route path="/adminwol/viewCategory" component={viewCategory} />
                    <Route exact path="/adminwol/createCategory/:currentId?" component={createCategory} />
                    <Route exact path="/adminwol/viewItem" component={viewItem} />
                    <Route exact path="/adminwol/createItem/:currentId?" component={createItem} />
                    <Route exact path="/adminwol/imageItem/:currentId?" component={imageItem} />
                    <Route exact path="/adminwol/detailItem/:currentId?" component={detailItem} />
                    <Route exact path="/adminwol/createFeature/:currentId?" component={createFeature} />
                    <Route exact path="/adminwol/createActivity/:currentId?" component={createActivity} />
                    <Route exact path="/adminwol/viewBooking" component={viewBooking} />
                    <Route exact path="/adminwol/bookingDetail/:currentId?" component={bookingDetail} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </Router>

            <ToastContainer></ToastContainer>
        </>
    )
}

export default Routes;
