
import Card from "@mui/material/Card";
import { Box, Grid, } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import env from "configs/vars";
import { commentItem, getItem, likeItem } from "store/actions/item";
import { Button, Comment, Share, Typography, WriteComment, } from "components/atoms";


const Comments = ({ data, dataUser }) => {
    const { item, } = data;
    const dispatch = useDispatch();

    const [disableSubmit, setDisableSubmit] = useState(false);
    const [comment, setComment] = useState('');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token')));


    // console.log('item', item);

    const userId = user?.user?.id;

    const handleLike = async () => {
        dispatch(likeItem({ idUser: dataUser?.account?.users_id }, item.id));
    };

    const Likes = () => {
        return <>{item?.likes?.length || 0}</>;
    };

    const LikesAct = () => {
        if (item?.likes) {
            return item?.likes.find((like) => like === dataUser?.account?.id)
                ? (<><FavoriteIcon color="secondary" fontSize="small" />&nbsp;Like</>)
                : (<><FavoriteBorderIcon color="secondary" fontSize="small" />&nbsp;Like</>);
        }
        return <><FavoriteBorderIcon color="secondary" variant="outlined" fontSize="small" />&nbsp;Like</>
    };

    const CountComments = () => {
        if (item?.comments) {
            return item?.comments.find((comment) => comment.id === dataUser?.account?.id)
                ? (<><CommentIcon color="primary" sx={{ mr: 1 }} /> <Typography variant="body2" title={`${item?.comments?.length} Penilaian`} /> </>)
                : (<><ChatBubbleOutlineIcon color="primary" sx={{ mr: 1 }} />   <Typography variant="body2" title={`${item?.comments?.length} Penilaian`} />   </>);
        }
        return <>
            <ChatBubbleOutlineIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="body2" title={`${item?.comments?.length || 0} Penilaian`} />
        </>;
    };

    const handleComment = async (e) => {
        e.preventDefault();
        setDisableSubmit(true)
        dispatch(commentItem({ idUser: dataUser?.account?.users_id, comment }, item.id));
        setComment('');
        setDisableSubmit(false)
    };


    return (
        <Box>
            <Card sx={{ p: 2, boxShadow: 5 }}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={2} sx={{ mb: 3 }}>
                            <Button size="small"
                                onClick={handleLike}
                                title={<><LikesAct /> (<Likes />)</>}
                            />
                        </Grid>
                        <Grid item xs={7} sx={{ mb: 3 }}>
                            <Button size="small" color="primary"
                                title={<><CountComments /> </>} />
                        </Grid>
                        {/* <Grid item xs={3} align="right" sx={{ mb: 3 }}>
                            <Share title={item?.title} />
                        </Grid> */}
                    </Grid>
                    <Grid item xs={12}>
                        {item?.comments?.map((c, i) => {
                            // const matchImagePost = users.find((user) => user._id === c.id);
                            // const data = { name: matchImagePost.name, image: matchImagePost.file, ...c, };
                            return (
                                <Comment
                                    key={i}
                                    src={`
                                    ${env.bucketItem}/users/${c.image_url}` ||
                                        user?.user?.user_metadata?.avatar_url
                                    }
                                    sx={{
                                        '& img': {
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                        },
                                        width: 40, height: 40,
                                    }}
                                    title={[c.name, c.comment]}
                                />
                            );
                        })}
                    </Grid>
                    <WriteComment
                        src={`${env.bucketItem}/users/${dataUser?.account?.file}` || user?.user?.user_metadata?.avatar_url}
                        sx={[{ width: 40, height: 40, }, { flexGrow: 1, borderRadius: 5, padding: 10, minWidth: 400, marginBottom: 10, }]}
                        onSubmit={handleComment}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        disabled={disableSubmit}
                    />
                </Grid>
            </Card >
        </Box >
    )
}

export default Comments
