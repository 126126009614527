import {
  CREATE,
  FETCH_ALL,
  FETCH_BY,
  UPDATE,
  DELETE,
  LIKE,
  COMMENT,
  FETCH_ALL_ITEM,
  FETCH_BY_ITEM,
} from "../../constants/actionTypes";

const initialState = { dataItemReducerAll: [], dataItemReducer: [] };

const itemReducer = (state = initialState, action) => {
  switch (action.type) {

    case CREATE:
      return {
        ...state,
      };
    case FETCH_ALL_ITEM:
      return {
        ...state,
        dataItemReducerAll: action.payload,
      };
    case FETCH_BY_ITEM:
      return {
        ...state,
        dataItemReducer: action.payload,
      };
    case UPDATE:
      return {
        ...state,
      };
    case DELETE:
      return {
        ...state,
      };

    case LIKE:
      return {
        ...state,
        dataItemReducer: action.payload,
      };

    case COMMENT:
      return {
        ...state,
        dataItemReducer: action.payload,
      };

    default:
      return state;
  }
};
export default itemReducer;
