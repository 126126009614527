import { AUTH, LOGOUT, FETCH_MENU, STATE_LOCATION, FETCHING_API } from "../../constants/actionTypes";

const authReducer = (state = { authData: null, userMenu: [], stateLocation: null, fetchApi: true }, action) => {
  switch (action.type) {
    case AUTH:
      localStorage.setItem("sb-wnpukijoybwfgrpearge-auth-token", JSON.stringify({ ...action.payload }));
      return { ...state, authData: action.payload, loading: false, errors: null };

    case LOGOUT:
      localStorage.clear();
      return { ...state, authData: null, loading: false, errors: null };

    case FETCH_MENU:
      return { ...state, userMenu: action.payload };

    case FETCHING_API:
      return { ...state, fetchApi: action.payload };

    case STATE_LOCATION:
      return { ...state, stateLocation: action.payload };
    default:
      return state;
  }
};

export default authReducer;
