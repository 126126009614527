import {
  CREATE,
  FETCH_ALL_BOOKING,
  FETCH_BY,
  UPDATE,
  DELETE,
  FETCH_PROCESS_BOOKING,
  FETCH_SEND_BOOKING,
  FETCH_DONE_BOOKING,
  FETCH_BY_BOOKING,
  FETCH_BY_TRACKING,
} from "../../constants/actionTypes";

const initialState = {
  dataBookingReducerAll: [],
  dataBookingReducerProcess: [],
  dataBookingReducerSend: [],
  dataBookingReducerDone: [],
  dataBookingReducer: [],
  dataTrackingReducer: {},
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE:
      return {
        ...state,
      };
    case FETCH_ALL_BOOKING:
      return {
        ...state,
        dataBookingReducerAll: {
          data: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        },
      };
    case FETCH_PROCESS_BOOKING:
      return {
        ...state,
        dataBookingReducerProcess: {
          data: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        },
      };
    case FETCH_SEND_BOOKING:
      return {
        ...state,
        dataBookingReducerSend: {
          data: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        },
      };
    case FETCH_DONE_BOOKING:
      return {
        ...state,
        dataBookingReducerDone: {
          data: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        },
      };
    case FETCH_BY_BOOKING:
      return {
        ...state,
        dataBookingReducer: {
          data: action.payload.data,
        }
      };

    case FETCH_BY_TRACKING:
      return {
        ...state,
        dataTrackingReducer: action.payload
      };

    case UPDATE:
      return {
        ...state,
      };
    case DELETE:
      return {
        ...state,
      };

    default:
      return state;
  }
};
export default bookingReducer;
