import { CREATE, FETCH_USER, FETCH_USERS } from "../../constants/actionTypes";

import axios from "axios";
const API = axios.create({ baseURL: process.env.REACT_APP_HOST });

// export const getUser = (email) => async (dispatch) => {
//   try {
//     const { data } = API.get(`/admin/account/${email}`)
//     console.log('data', data);
//     dispatch({ type: FETCH_USER, payload: data });
//     return data;
//   } catch (error) {
//     throw error
//   }
// };

export const getUser = (email, successCB, failedCB) => async (dispatch) => {
  API.get(`/admin/account/${email}`)
    .then((response) => {
      const resAPI = response.data;
      dispatch({ type: FETCH_USER, payload: resAPI });
      return successCB && successCB(resAPI);
    })
    .catch((err) => {
      return failedCB && failedCB(err);
    });
};

export const getUserByRole = (param, successCB, failedCB) => async (dispatch) => {
  console.log("getUser callerd===>>", param)
  API.get(`/admin/account/${param}/role`)
    .then((response) => {
      const resAPI = response.data;
      dispatch({ type: FETCH_USER, payload: resAPI });
      return successCB && successCB(resAPI);
    })
    .catch((err) => {
      return failedCB && failedCB(err);
    });
};

export const getUsers = (currentId, successCB, failedCB) => async (dispatch) => {
  API.get(`/admin/account`)
    .then((response) => {
      const { data } = response;
      dispatch({ type: FETCH_USERS, payload: data });
      return successCB && successCB(data);
    })
    .catch((err) => {
      return failedCB && failedCB(err);
    });
};

export const editUser = (payload) => async (dispatch) => {
  try {
    const { data } = await API.put(`/admin/account`, payload);
    // dispatch({ type: FETCH_USERS, payload: resAPI });
  } catch (error) {
    throw error
  }
};

export const createUser = (payload) => async () => {
  try {
    const formData = new FormData();
    formData.append("userId", payload.userId);
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("coordinatUser", payload.coordinatUser);
    return API.post("/admin/account", formData)
  } catch (error) {
    throw error
  }
};