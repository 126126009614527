/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  Container,
  Pagination,
  PaginationItem,
  Stack,
} from "@mui/material";

import {
  getAllBooking,
  getDoneBooking,
  getProcessBooking,
  getSendBooking,
} from "store/actions/booking";
import { isCompositeComponentWithType } from "react-dom/test-utils";

const Paginate = ({ page, status }) => {
  console.log("status=====>>", status);
  const {
    dataBookingReducerAll,
    dataBookingReducerProcess,
    dataBookingReducerSend,
    dataBookingReducerDone,
  } = useSelector((state) => state.bookingReducer);
  const user = useState(
    JSON.parse(localStorage.getItem("sb-wnpukijoybwfgrpearge-auth-token"))
  );
  const dispatch = useDispatch();

  const pageCounts = {
    Semua: dataBookingReducerAll.numberOfPages,
    Dikemas: dataBookingReducerProcess.numberOfPages,
    Dikirim: dataBookingReducerSend.numberOfPages,
    Selesai: dataBookingReducerDone.numberOfPages,
  };
  const count = pageCounts[status];
  const userId = user[0]?.user?.id;

  useEffect(() => {
    if (status === "" || status.length === 0) {
      dispatch(getAllBooking(userId, "", page, ""));
    }
    if (status === "Proses") {
      dispatch(getProcessBooking(userId, "Proses", page));
    }
    if (status === "Kirim") {
      dispatch(getSendBooking(userId, "Kirim", page));
    }
    if (status === "Selesai") {
      dispatch(getDoneBooking(userId, "Selesai", page));
    }
  }, [dispatch, page]);

  return (
    <Container>
      <Card sx={{ padding: 2, mt: 1 }}>
        <Stack
          spacing={2}
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Pagination
            count={count}
            page={Number(page) || 1}
            color="primary"
            renderItem={(item) => (
              <PaginationItem
                {...item}
                component={Link}
                to={`/purchase?page=${item.page}`}
              />
            )}
          />
        </Stack>
      </Card>
    </Container>
  );
};

export default Paginate;
