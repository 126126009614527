import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, } from "react-toastify";
import { Container, Paper, } from "@mui/material";

import { Header } from "components";
import ChatPage from "components/templates/ChatPage";


function Message() {
  const { cartItems } = useSelector((state) => state.cartReducer);
  const { dataLandingPageReducerAll } = useSelector((state) => state.landingPageReducer);


  return (
    <Container spacing={2}>
      <Header cart={cartItems} data={dataLandingPageReducerAll.category} />
      <ToastContainer autoClose={8000} />
      <ChatPage />
    </Container>
  );
}

export default Message