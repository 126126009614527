import { FETCH_MESSAGE, FETCH_MESSAGE_BY, CREATE_MESSAGE, DELETE, UPDATE, CLEAR_MESSAGE, } from 'constants/actionTypes';


const messagesReducer = (state = { messages: [], messageBy: [] }, action) => {
    switch (action.type) {

        case FETCH_MESSAGE:
            return { ...state, messages: action.payload };

        case FETCH_MESSAGE_BY:
            return { ...state, messageBy: action.payload };
        case CLEAR_MESSAGE:
            return {
                ...state,
                messageBy: [],
            };

        // case CREATE_MESSAGE:
        //   return { ...state, messages: [...state.messages, action.payload] };

        // case UPDATE:
        //   return { ...state, messages: state.messages.map((message) => (message._id.$oid === action.payload._id.$oid ? action.payload : message)) };

        // case DELETE:
        //   return { ...state, messages: state.messages.filter((message) => message._id.$oid !== action.payload._id.$oid) };

        default:
            return state;
    }
};

export default messagesReducer;