import React from "react";
import { useSelector } from "react-redux";
import { ToastContainer, } from "react-toastify";
import { Card, Container, } from "@mui/material";

import { UserPageMobile, Header, } from "components";


function UserMobile() {
    const { cartItems } = useSelector((state) => state.cartReducer);
    const { dataLandingPageReducerAll } = useSelector((state) => state.landingPageReducer);


    return (
        <Container spacing={2} maxWidth="sm">
            <Header cart={cartItems} data={dataLandingPageReducerAll.category} />
            <ToastContainer autoClose={8000} />

            <Card>
                <UserPageMobile />
            </Card>
        </Container>
    );
}

export default UserMobile