import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, } from "react-toastify";
import { Container, } from "@mui/material";

import { DashboardPage, Header, Loading, } from "components";


function Dashboard() {
    const { cartItems } = useSelector((state) => state.cartReducer);
    const { dataLandingPageReducerAll } = useSelector((state) => state.landingPageReducer);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const user = JSON.parse(
            localStorage.getItem("sb-wnpukijoybwfgrpearge-auth-token")
        );
        if (user)
            setIsLoading(false);
    }, []);


    return (
        <Container spacing={2}>
            <Header cart={cartItems} data={dataLandingPageReducerAll.category} />
            <ToastContainer autoClose={8000} />
            <DashboardPage />
        </Container>
    );
}

export default Dashboard