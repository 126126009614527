import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, } from "react-toastify";
import { Container, } from "@mui/material";

import { PurchasePage, Header, } from "components";


function Purchase() {
  const { cartItems } = useSelector((state) => state.cartReducer);
  const { dataBookingReducerAll, dataBookingReducerProcess, dataBookingReducerSend, dataBookingReducerDone, } = useSelector((state) => state.bookingReducer);
  const { dataLandingPageReducerAll } = useSelector((state) => state.landingPageReducer);


  return (
    <Container spacing={2}>
      <Header cart={cartItems} data={dataLandingPageReducerAll?.category} />
      <ToastContainer autoClose={8000} />
      {
        <PurchasePage
          all={dataBookingReducerAll.data}
          process={dataBookingReducerProcess.data}
          send={dataBookingReducerSend.data}
          done={dataBookingReducerDone.data}
        />
      }
    </Container>
  );
}

export default Purchase
