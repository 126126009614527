import React, { useState, useEffect, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useLocation } from 'react-router-dom';
import { getAllLandingPage, getLandingPage } from "store/actions/landingPage";
import { Categories } from "components/molecules";
import { Spinner } from "components/atoms";
import { isEmpty } from "utils/string";
import PencilLoader from "components/atoms/PencilLoader";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function LandingPage() {
  const user = useState(JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token')));
  const [openDel, setOpenDel] = useState(false);
  const dispatch = useDispatch();

  const query = useQuery();
  const searchQuery = query.get('searchQuery');

  const { dataLandingPageReducer } = useSelector((state) => state.landingPageReducer);
  const { fetchApi } = useSelector((state) => state.auth);

  const welcome = () =>
    toast.info(`${user?.user_metadata?.full_name ? `Hi, ` + user?.user_metadata?.full_name : ""}
    Welcome to my practice web`, {
      autoClose: 4000,
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    // document.title = "Home";
    if (isEmpty(searchQuery))
      dispatch(getAllLandingPage());
    setOpenDel(true);
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(searchQuery))
      dispatch(getLandingPage());
  }, [dispatch]);

  const handleClose = () => {
    setOpenDel(false);
  };

  return (
    <>
      {
        // !fetchApi ? (
        dataLandingPageReducer.category ? (
          <>
            <Categories data={dataLandingPageReducer?.category} searchQuery={searchQuery} />

            <Dialog open={openDel} onClose={handleClose}>
              <iframe
                width="560"
                height="315"
                src={`https://odysee.com/$/embed/@HairulDev:6/efood:8`}
                title="Odysee video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <DialogContent style={{ textAlign: "center" }}>
                <DialogContentText style={{ marginBottom: 20, }}>
                  Download versi android <a href="https://drive.google.com/file/d/1V3VIqc7_XoeCJRvherMXWFc3GrKZsESa/view?usp=drive_link" target="_blank">disini</a>
                </DialogContentText>
                <DialogContentText style={{ marginBottom: 5, }}>
                  Account demo
                </DialogContentText>
                <DialogContentText>
                  Email : for.latihan4@yopmail.com
                </DialogContentText>
                <DialogContentText>
                  Password : P@ssw0rd4
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Batal</Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <PencilLoader />
        )
      }
    </>
  );
}

export default LandingPage