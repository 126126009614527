import React, { useState } from 'react'

import MessageIcon from '@mui/icons-material/Message';
import { makeStyles } from '@mui/styles';
import { SpeedDial as SpeedDialMUI, SpeedDialIcon, SpeedDialAction } from '@mui/lab';
import Chat from 'components/organisms/Chat';
import { Popover } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    speedDial: {
        position: 'fixed',
        bottom: 2,
        right: 2,
    },
}));

const SpeedDial = () => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <SpeedDialMUI
                ariaLabel="SpeedDial example"
                sx={{ pl: 85 }}
                className={classes.speedDial}
                icon={<MessageIcon />}
            // onClick={handleClick}
            >
            </SpeedDialMUI>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Chat />
            </Popover>
        </>
    )
}

export default SpeedDial