import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import supabase from 'configs/supabase';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Container } from '@mui/material';
import { Header, Leaflet, Spinner, Typography } from 'components';
import { showTracking } from 'store/actions/booking';
import { isCompositeComponentWithType } from 'react-dom/test-utils';
import { isEmpty } from 'utils/string';
import LeafletMobile from '../LeafletMobile';


const MobilePurchase = () => {
    const dispatch = useDispatch();
    const { id, email } = useParams();

    const { dataTrackingReducer } = useSelector((state) => state.bookingReducer);

    useEffect(() => {
        dispatch(showTracking(email, id));
    }, []);

    useEffect(() => {
        const profileSubscription = supabase.channel('custom-update-channel')
            .on(
                'postgres_changes',
                { event: 'UPDATE', schema: 'public', table: 'profile' },
                (payload) => {
                    dispatch(showTracking(email, id));
                    console.log('Change received!');
                }
            )
            .subscribe();
        return () => {
            profileSubscription.unsubscribe();
        };
    }, []);

    return (
        <>
            <ToastContainer autoClose={8000} />
            {
                isEmpty(dataTrackingReducer) ? (<Spinner />) : (
                    <>
                        <Card sx={{ padding: 1, }}>
                            <LeafletMobile data={dataTrackingReducer} />
                        </Card>
                    </>
                )}
        </>
    );
}

export default MobilePurchase;
