import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    Grid, Typography, Divider, Button,
} from "@mui/material";
import env from 'configs/vars';
import convertToRupiah from 'utils/formatCurrency';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { CartItem } from 'components/molecules';

const PurchaseItem = ({ data }) => {
    const dataPurchase = data?.data;
    const history = useHistory();
    const { id } = useParams();

    const purchaseItemDetail = (id) => {
        history.push(`/purchase/${id}`);
    }


    return (
        <>
            {dataPurchase?.map((item, index) => (
                <Grid item xs={12} sm={12} key={item.id} >
                    <Grid container>
                        <Grid item xs={4} >
                            <Typography variant="body2" color="secondary">Invoice #{item.invoice}</Typography>
                        </Grid>
                        <Grid item xs={6} >
                            {!id && item.payments_status !== "Selesai" && item.payments_status !== "Proses" && (
                                <Grid container>
                                    <Grid item xs={1} sx={{ mr: 2 }}>
                                        <LocalShippingIcon color='primary' />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" color="primary" onClick={() => purchaseItemDetail(item.id)}>
                                            Lacak pengiriman</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {item.payments_status === "Selesai" && (
                                <Grid>
                                    <Grid item xs={8} sx={{ mr: 1 }}>
                                        <LocalShippingIcon color='primary' />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" color="primary" onClick={() => purchaseItemDetail(item.id)}>
                                            Paket telah di terima ybs</Typography>
                                    </Grid>
                                </Grid>
                            )
                            }
                        </Grid >
                        <Grid item align="right" xs={2}>
                            <Typography variant="body2" color="primary" fontWeight="bold">{item.payments_status.toUpperCase()}</Typography>
                        </Grid>
                    </Grid >
                    {
                        item.items.map((el) => (
                            <div key={el.id}>
                                <CartItem
                                    id={el.id}
                                    title={el.title}
                                    quantity={el.qty}
                                    price={el.price}
                                    unit={el.unit}
                                    image={`${env.bucketItem}/item/${el.images.image_url} `}
                                    status={1}
                                />
                            </div>
                        ))
                    }
                    <Grid container >
                        <Grid item xs={9} >
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" color="div">Total Pesanan : </Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ ml: 1 }} variant="body2" color="primary" fontWeight="bold">{convertToRupiah(item.total)}</Typography>
                        </Grid>
                    </Grid >
                    <Divider key={item.id} sx={{ mb: 4 }} />
                </Grid >
            ))}

        </>
    );
};

export default PurchaseItem;
