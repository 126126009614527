
import Box from "@mui/material/Box";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  ImageList,
  ImageListItem,
} from "@mui/material";

import { getItem, getItemImage } from "store/actions/item";
import env from "configs/vars";


export default function Detail() {
  const initialState = {
    _id: "",
    imageUrl: "",
  };
  const dispatch = useDispatch();
  const params = useParams();
  const { currentId } = params;

  const [image, setImage] = useState(initialState);
  const { dataItemReducer } = useSelector((state) => state.itemReducer);

  useEffect(() => {
    dispatch(getItem(currentId));
  }, [dispatch]);


  console.log("dataItemReducer", dataItemReducer);

  return (
    <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
      <ImageListItem >
        <img
          src={`${env.bucketItem}/item/${dataItemReducer.item.images.image_url}`}

          // alt={item.image_url}
          loading="lazy"
        />
      </ImageListItem>
    </ImageList>
  )
}
