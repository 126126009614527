import {
  CREATE,
  FETCH_PAGE,
  FETCH_BY,
  UPDATE,
  DELETE,
  START_LOADING,
  END_LOADING,
  FETCH_ALL_PAGE,
  FETCH_BY_SEARCH,
} from "../../constants/actionTypes";

const initialState = {
  isLoading: true,
  dataLandingPageReducerAll: [],
  dataLandingPageReducer: [],
};

const landingPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case CREATE:
      return {
        ...state,
      };
    case FETCH_ALL_PAGE:
      return { ...state, dataLandingPageReducerAll: action.payload };
    case FETCH_PAGE:
    case FETCH_BY_SEARCH:
      return { ...state, dataLandingPageReducer: action.payload };
    case UPDATE:
      return {
        ...state,
      };
    case DELETE:
      return {
        ...state,
      };

    default:
      return state;
  }
};
export default landingPageReducer;
