import {
  ADD_CART,
  CLEAR_CART,
  DELETE,
  REMOVE_CART,
  UPDATE_CART,
} from "../../constants/actionTypes";

const initialState = { cartItems: [], };

function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CART:
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };
    case UPDATE_CART:
      return {
        ...state,
        cartItems: state.cartItems.map((cart) =>
          cart.id === action.payload.id ? action.payload : cart
        ),
      };
    case REMOVE_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter((cart) => cart.id !== action.payload),
      };
    case CLEAR_CART:
      return {
        ...state,
        cartItems: [],
      };
    case 'CLEAR_PERSISTED_STATE':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
export default cartReducer;
