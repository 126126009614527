import {
  Avatar,
  Button,
  Card,
  Container,
  Fade,
  Grid,
  List,
  ListItem,
  Modal,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import env from "configs/vars";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  fetchMessage,
  fetchMessages,
} from "store/actions/messages";
import SendIcon from "@mui/icons-material/Send";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import style from "./styles";

import io from "socket.io-client";
import { getUser } from "store/actions/user";
import { isEmpty } from "utils/string";

function urlToAnchorText(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => `<a href="${url}">${url}</a>`);
}

const Chat = () => {
  const dispatch = useDispatch();
  const messagesContainer = useRef(null);

  const initialsState = {
    _id: "",
    body: "",
    username: "",
    email: "",
    image: "",
    user_created: "",
    updated_at: "",
    created_at: "",
  };

  const { messages, messageBy } = useSelector((state) => state.messagesReducer);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialsState);
  const { dataUser } = useSelector((state) => state.userReducer);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token')));

  const newSocket = io(env.reactAppHost);


  useEffect(() => {
    newSocket.on("connect", () => {
      console.log('Socket.IO web connected');
    });

    newSocket.on("create", (newData) => {
      const parsedData = JSON.parse(newData);
      dispatch(fetchMessages([...messages, parsedData]));
    });

    newSocket.on("update", (newData) => {
      const parsedData = JSON.parse(newData);
      dispatch(fetchMessages([...messages, parsedData]));
    });

    newSocket.on("delete", () => {
      dispatch(fetchMessages());
    });

    return () => {
      newSocket.disconnect();
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMessages());
    setForm(initialsState);
    dispatch(clearMessage());
  }, [dispatch]);

  // console.log("user===>>", user)
  // console.log("dataUser===>>", dataUser);
  // console.log("messages===>>", messages);

  useEffect(() => {
    if (!isEmpty(user))
      dispatch(getUser(user.user.email));
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let inputValue = {
      body: form.body,
      username: dataUser.account?.name,
      email: dataUser.account.email,
    };
    e.target.body.value = "";


    if (!form._id) {
      newSocket.emit("create", inputValue);
      dispatch(clearMessage());
      setForm(initialsState)
    } else {
      newSocket.emit("update", { id: form._id, ...inputValue });
      setForm(initialsState)
      dispatch(clearMessage());
      handleClose();
    }
  };

  const handleFetchMessage = (id) => {
    dispatch(fetchMessage(id));
    setOpen(true);
  };

  useEffect(() => {
    setForm(messageBy);
  }, [messageBy]);

  const [openDel, setOpenDel] = useState(false);
  const [idDel, setIdDel] = useState(null);

  const DeleteModalPrompt = (id) => {
    id && setOpenDel(true);
    setIdDel(id);
  };

  const handleDelete = (idDel) => {
    newSocket.emit("delete", idDel);
    dispatch(clearMessage());
    handleClose();
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDel(false);
  };

  useEffect(() => {
    if (messagesContainer.current)
      messagesContainer.current.scrollTop =
        messagesContainer.current.scrollHeight;
  }, [messages]);

  return (
    <Container component="main" maxWidth="sm">
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={1}>
              <Avatar
                // src={
                //   dataUser.account.file
                //     ? `${env.bucketItem}/users/${dataUser.account.file}`
                //     : "/images/users/profile.png"
                // }
                src="/images/users/profile.png"
                sx={style.messageAvatar}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={style.messageName}>
                {dataUser?.account?.name}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant="body2"
                style={{ textAlign: "right" }}
                sx={style.messageName}
              >
                Chat with us!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <List sx={style.messages} ref={messagesContainer}>
            {messages.map((message) => {
              return (
                <ListItem key={message._id}>
                  {message.email === dataUser.account.email ? (
                    <>
                      <Grid item xs={1}>
                        <Avatar
                          src="/images/users/profile.png"
                          sx={style.messageAvatar}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Grid container direction="column">
                          <Grid container sx={style.messageBody}>
                            <Grid item xs={9}>
                              <Typography
                                variant="body2"
                                sx={style.messageName}
                              >
                                {message.username}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: "right" }}>
                              {dataUser.account.email === message.email && (
                                <Button
                                  style={{ color: "black" }}
                                  size="small"
                                  onClick={() =>
                                    handleFetchMessage(message?._id)
                                  }
                                >
                                  <MoreHorizIcon
                                    fontSize="default"
                                  />
                                </Button>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                sx={style.messageText}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: urlToAnchorText(message.body),
                                  }}
                                ></p>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} align="right">
                              {dataUser.account.email === message.email && (
                                <>
                                  <Typography
                                    variant="subtitle2"
                                    color="error"
                                    onClick={() =>
                                      DeleteModalPrompt(message._id)
                                    }
                                  >
                                    Hapus pesan
                                  </Typography>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={11}>
                        <Grid container direction="column">
                          <Grid container sx={style.messageBody}>
                            <Grid item xs={9}>
                              <Typography
                                variant="body2"
                                sx={style.messageName}
                              >
                                {message.username}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: "left" }}>
                              {dataUser.account.email === message.email && (
                                <Button
                                  style={{ color: "black" }}
                                  size="small"
                                  onClick={() =>
                                    handleFetchMessage(message?._id)
                                  }
                                >
                                  <MoreHorizIcon fontSize="default" />
                                </Button>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                sx={style.messageText}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: urlToAnchorText(message.body),
                                  }}
                                ></p>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} align="right">
                              {dataUser.account.email === message.email && (
                                <>
                                  <Typography
                                    variant="subtitle2"
                                    color="error"
                                    onClick={() =>
                                      DeleteModalPrompt(message._id)
                                    }
                                  >
                                    Hapus pesan
                                  </Typography>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1}>
                        <Avatar
                          src="/images/users/profile.png"
                          sx={style.messageAvatar}
                          align="right"
                        />
                      </Grid>
                    </>
                  )}
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={12}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                onChange={onChange}
                name="body"
                required
                placeholder="Kirim pesan ?"
                autoFocus
                style={{
                  padding: "5px 10px 10px 25px",
                  minWidth: 350,
                }}
              />
              <Button type="submit" variant="outlined" color="primary">
                <SendIcon />
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        sx={style.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <Grid container alignItems="stretch" sx={{ p: 2 }}>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <Grid container sx={style.messageBody}>
                      <Grid item xs={12}>
                        <Typography variant="body2" sx={style.messageName}>
                          {dataUser?.account?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          sx={style.messageText}
                          onChange={onChange}
                          value={form.body}
                          name="body"
                          placeholder="Kirim pesan ?"
                          autoFocus
                          multiline
                          rows={3}
                          style={{
                            minWidth: 350,
                            width: "100%",
                          }}
                        />
                      </Grid>
                      <Grid item xs={2} sx={style.messageEditButton}>
                        <Button
                          type="submit"
                          variant="outlined"
                          color="primary"
                          sx={{ pl: 3 }}
                        >
                          <SendIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </form>
        </Fade>
      </Modal>

      <Dialog open={openDel} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Anda yakin menghapus pesan ini ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button onClick={() => handleDelete(idDel)} color="error">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Chat;
