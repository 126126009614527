import React, { useState, } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import LoginIcon from '@mui/icons-material/Login';
import {
  AppBar, Box, Grid, Toolbar, IconButton, Typography, Badge, MenuItem, Menu,
  Popover, Avatar,
} from "@mui/material";


import { useHistory, } from "react-router-dom";
import { useDispatch, } from "react-redux";
import HeadShake from "react-reveal/HeadShake";
import logo from "assets/images/logo.png";
import { CartItem, SearchFeature, } from "components";
import env from "configs/vars";
import convertToRupiah from "utils/formatCurrency";
import supabase from "configs/supabase";

export default function PrimarySearchAppBar({ cart, data }) {
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorElMenu);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorElMenu(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const ls = JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token'));
  const [user, setUser] = useState(ls);


  const dispatch = useDispatch();
  const history = useHistory();


  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const totalQty = cart.reduce((acc, item) => acc + parseInt(item.qty), 0);



  const logout = async () => {
    await supabase.auth.signOut();
    localStorage.clear();
    history.push("/auth")
  };

  const handlePurchase = (event) => {
    history.push("/purchase")
  };

  const filteredData = data?.filter(({ items }) =>
    items?.find((item) => cart.find((el) => el.id === item.id))
  );
  const totalOrder = cart.reduce((accumulator, item) => accumulator + item.subtotal, 0)
  const totalProduct = cart.length;



  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorElMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={logout}> <LogoutTwoToneIcon sx={{ mr: 1 }} /> Logout </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label={`show ${totalQty} new carts`} color="inherit">
          <Badge badgeContent={`${totalQty}`} color="error">
            <ShoppingCartIcon onClick={handleClick} />
          </Badge>
        </IconButton>
        <p>Carts</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handlePurchase}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <LocalShippingIcon sx={{ mr: 1 }} />
        </IconButton>
        <p>Pesanan Saya</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, mb: 10 }}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Avatar
            component="a"
            href="/"
            src={logo} sx={{ mr: 1, width: 30, height: 30 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              display: { xs: 'none', sm: 'block' }, mr: 2,
              fontFamily: 'monospace',
              fontWeight: 600,
              letterSpacing: '.2rem',
              color: 'inherit',
              textDecoration: "none"
            }}
          >
            warong Online
          </Typography>

          <SearchFeature
            searchSubject="landingPage"
          />
          <Box sx={{ flexGrow: 1 }} />
          {user ? (
            <>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <IconButton
                  size="large"
                  aria-label={`show ${totalQty} new carts`}
                  color="inherit"
                >
                  <Badge
                    color="error"
                    badgeContent={`${totalQty}`}
                    onClick={handleClick}
                  >
                    <HeadShake spy={`${totalQty}`}>
                      <ShoppingCartIcon onClick={handleClick} />
                    </HeadShake>
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="Pesanan Saya"
                  color="inherit" onClick={handlePurchase}
                >
                  <LocalShippingIcon />
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </>
          ) : (
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              href="/auth"
            >
              <LoginIcon /> &nbsp; <Typography variant="h6">Login</Typography>
            </IconButton>
          )}

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >

            {filteredData?.map((item, index) => (
              <Grid item xs={12} key={index} sx={{ p: 1, width: 450 }}>
                {item.items.map((el) => (
                  <>
                    {cart.map((cartItem) => {
                      if (cartItem.id === el.id) {
                        return (
                          <CartItem
                            key={cartItem.id}
                            image={`${env.bucketItem}/item/${el.images.image_url} `}
                            title={cartItem.title}
                            price={el.price}
                            unit={el.unit}
                            id={cartItem.id}
                            quantity={cartItem.qty}
                            status={2}
                          />
                        );
                      }
                      return null;
                    })}
                  </>
                ))}
                <Grid container>
                  <Grid item xs={8} >
                    <Typography align="center" variant="subtitle2" color="text.secondary">Total Pesanan ({totalProduct} Item) : </Typography>
                  </Grid>
                  <Grid item xs={4} >
                    <Typography align="left" variant="subtitle2" color="secondary">{convertToRupiah(totalOrder)} </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Popover>

        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>




  );
}