import React, { useState } from 'react';
import { Button, IconButton, InputAdornment, TableCell, TableContainer, Table, TableBody, TableRow, Typography, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { removeFromCart, updateCart } from 'store/actions/cart';
import { useDispatch } from 'react-redux';
import convertToRupiah from 'utils/formatCurrency';

const CartItem = (props) => {
    const { image, title, price, unit, quantity, id, status } = props;

    let subTotal = price * quantity
    const initialState = {
        id: id,
        title: title,
        qty: quantity,
        subtotal: subTotal,
    };
    const dispatch = useDispatch();
    const [form, setForm] = useState(initialState);
    const { qty } = form;

    const incrementQuantity = () => {
        setForm({ ...form, qty: quantity + 1 });
        dispatch(updateCart({ ...form, qty: quantity + 1, subtotal: price * (quantity + 1) }));
    };

    const decrementQuantity = () => {
        if (qty > 1) {
            setForm({ ...form, qty: quantity - 1 });
            dispatch(updateCart({ ...form, qty: quantity - 1, subtotal: price * (quantity - 1) }));
        }
    };

    const deleteToCart = (id) => {
        dispatch(removeFromCart(id))
    };

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };



    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell width="5%">
                            <img src={image} alt={title} style={{ height: 50, width: 50 }} />
                        </TableCell>
                        <TableCell width="30%">
                            <Typography variant="body2" component="div">{title}</Typography>
                        </TableCell>
                        {status !== 2 && (
                            <TableCell width="20%">
                                <Typography variant="body2" component="div">{convertToRupiah(price)} / {unit}</Typography>
                            </TableCell>
                        )}
                        <TableCell width="20%">
                            <TextField
                                size="small"
                                onChange={onChange}
                                value={form.id}
                                name="id"
                                required
                                fullWidth
                                style={{
                                    display: "none"
                                }}
                            />
                            {status === 0 ? (
                                <TextField
                                    size="small"
                                    label="Qty"
                                    name="qty"
                                    value={quantity}
                                    onChange={onChange}
                                    sx={{ minWidth: 100, maxWidth: 120 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="decrement quantity"
                                                    onClick={decrementQuantity}
                                                    edge="end"
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="increment quantity"
                                                    onClick={incrementQuantity}
                                                    edge="end"
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1,
                                            step: 1
                                        }
                                    }}
                                />
                            ) : (
                                <Typography variant="body2" component="div">
                                    {status === 2 ? <>{quantity} {unit}</> : `x ${quantity} Qty`}
                                </Typography>
                            )
                            }
                        </TableCell>
                        <TableCell width="20%">
                            <TextField
                                size="small"
                                name="subtotal"
                                value={subTotal}
                                style={{ display: "none" }}
                            />
                            <Typography variant="body2" component="div" >
                                {convertToRupiah(subTotal)}
                            </Typography>
                        </TableCell>
                        {(status === 0 || status === 2) && (
                            <TableCell width="5%">
                                <Button onClick={() => deleteToCart(id)}><DeleteForeverIcon style={{ color: "red" }} /></Button>
                            </TableCell>
                        )}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer >
    );
};

export default CartItem;
