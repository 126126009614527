import { ADD_CART, ADD_CHECKOUT, CLEAR_CART, REMOVE_CART, UPDATE_CART } from "constants/actionTypes";

export const addCart =
  (data) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_CART,
        payload: data
      });
    } catch (error) {
      throw error
    }
  };

export const addCheckout =
  (data) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_CHECKOUT,
        payload: data
      });
    } catch (error) {
      throw error
    }
  };

export const updateCart =
  (data) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_CART,
        payload: data
      });
    } catch (error) {
      throw error
    }
  };

export const removeFromCart =
  (itemId) => async (dispatch) => {
    try {
      console.log('itemId===>>', itemId);
      dispatch({ type: REMOVE_CART, payload: itemId });
    } catch (error) {
      throw error
    }
  };

export const clearCart =
  () => async (dispatch) => {
    try {
      dispatch({ type: CLEAR_CART });
    } catch (error) {
      throw error
    }
  };

