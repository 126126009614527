import React, { useState, } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, } from "react-redux";
import { Box, Badge, Button, Container, Grid, Modal, Typography, Popover, Backdrop, InputAdornment, Card, } from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import beepSound from '../../../assets/beep.mp3';
import Fade from '@mui/material/Fade';
import { Comment } from 'react-loader-spinner'
import { getBySearchBooking } from "store/actions/booking";
import { Search, SearchInput } from "components/atoms";
import { getBySearchLandingPage } from "store/actions/landingPage";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const SearchFeature = (props) => {
  let { border, color, searchSubject } = props;
  if (!color) {
    color = "white";
  }

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);


  const startSpeechToText = () => {
    setDisableButton(true);
    setOpenModal(true);
    setSearch("");
    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = 'id-ID'; // change language to Indonesian
    recognition.onstart = () => {
      const beep = new Audio(beepSound);
      beep.play();
    };
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setSearch(transcript);
      setDisableButton(false);
      recognition.stop();
    };
    recognition.start();
  };

  const handleClosePractice = () => {
    setOpenModal(false)
    setDisableButton(false);
  };

  const searchPost = () => {
    if (search.trim()) {
      if (searchSubject === "landingPage") {
        dispatch(getBySearchLandingPage({ search }));
        history.push(`/search?searchQuery=${search || 'none'}`);
      } else {
        dispatch(getBySearchBooking({ search }, user.result.email));
        history.push(`/purchase/search?searchQuery=${search || 'none'}`);
      }
      setOpenModal(false)
    } else {
      history.push('/');
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };


  return (
    <>
      <SearchInput
        onKeyDown={handleKeyPress}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        autoComplete="off"
        name="search"
        placeholder="Cari Item..."
        border={border}
        inputProps={{ 'aria-label': 'search' }}
        endAdornment={(
          <InputAdornment position="end">
            <SearchIcon onClick={searchPost}
              sx={{ color: { color }, mr: 1, cursor: 'pointer', }} />
            <KeyboardVoiceIcon
              onClick={startSpeechToText}
              disabled={disableButton}
              starticon={<KeyboardVoiceIcon />}
              sx={{ color: { color }, mr: 1, cursor: 'pointer', }} />
          </InputAdornment>
        )}
      />


      <Modal
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onClose={handleClosePractice}
        aria-describedby="modal-modal-description"
        open={openModal}>

        <Fade in={openModal}>
          <Box sx={style}>
            <Comment
              visible={true}
              height="50"
              width="50"
              ariaLabel="comment-loading"
              wrapperStyle={{}}
              wrapperClass="comment-wrapper"
              color="#fff"
              backgroundColor="#6b88fb"
            />
            <Grid container alignItems="center">
              <Grid item xs={10} align="center">
                <Typography id="modal-modal-description" >
                  {search ? `Cari "${search}" ?` : `Sedang mendengarkan ...`}
                </Typography>
              </Grid>
              <Grid item align="left">
                {search ?
                  <Button onClick={searchPost} variant="contained" color="secondary">
                    <SearchIcon />
                  </Button> : ""
                }
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>

    </>
  );
}
export default SearchFeature;
