import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import env from "configs/vars";
import { CardItem, SpeedDial } from "components";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import {
  AppBar, Box, Button, Card, Container, CssBaseline, Fab, FormControlLabel, FormGroup, Grid,
  IconButton, Popover, Switch, Toolbar, Typography
} from "@mui/material";
import ImportExportRoundedIcon from '@mui/icons-material/ImportExportRounded';

import { useLocation } from 'react-router-dom';
import { useState } from "react";
import { filterElasticSearch } from "store/actions/landingPage";
import { isEmpty } from "utils/string";



function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Categories({ data, searchQuery }) {
  const dispatch = useDispatch();

  const query = useQuery();
  const searchQueryUrl = query.get('searchQuery');
  const [isPopular, setIsPopular] = useState("");
  const [sortOrder, setSortOrder] = useState('asc');

  const handlePopular = () => {
    setIsPopular(isPopular === 1 ? 0 : 1);
  };
  const handlePrice = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
  };


  useEffect(() => {
    if (!isEmpty(searchQueryUrl))
      dispatch(filterElasticSearch(isPopular, sortOrder));
  }, [isPopular, sortOrder]);

  const filteredData = data?.filter((el) => el.items?.length > 0);
  console.log("filteredData============>>>", filteredData)

  return filteredData?.map((el, index) => (
    <Container key={`category-${index}`}>
      <Card sx={{ borderRadius: "0px 0px 0px 0px" }}>
        <Grid xs={12}>
          <Typography
            gutterBottom
            variant="body"
            align="center"
            sx={{ m: 2, display: 'flex', alignItems: 'center', textAlign: 'center' }}
          >{el.name}
          </Typography>
        </Grid>
        <Grid container sx={{ justifyContent: "center" }}>
          {el.items.map((item, index) => (
            <CardItem
              key={index}
              id={item.id}
              image={
                item.images
                  ? `${env.bucketItem}/item/${item.images.image_url}`
                  : ""
              }
              title={item.title}
              price={item.price}
              unit={item.unit}
            />
          ))}
        </Grid>
      </Card>
    </Container >
  ));
}
