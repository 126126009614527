import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from "@mui/material/Button";

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '0.7rem',
                    padding: '3px 7px',
                },
            },
        },
    },
});

const SmallButton = ({ title, ...rest }) => {
    return (
        <ThemeProvider theme={theme}>
            <Button {...rest}>
                {title}
            </Button>
        </ThemeProvider>
    );
}

export default SmallButton;
