import React from 'react'
import { useSelector } from "react-redux";
import { Cart, LandingPage, } from "components";

const DashboardPage = () => {
    const { cartItems } = useSelector((state) => state.cartReducer);
    const { dataLandingPageReducerAll } = useSelector((state) => state.landingPageReducer);


    return (
        <>
            <Cart data={dataLandingPageReducerAll?.category} cart={cartItems} />
            <LandingPage />
        </>
    )
}

export default DashboardPage