import axios from "axios";
import env from "configs/vars";
import { CLEAR_MESSAGE, CREATE_MESSAGE, FETCH_MESSAGE, FETCH_MESSAGE_BY } from "constants/actionTypes";


const API = axios.create({ baseURL: `${env.reactAppHost}` });


export const fetchMessage =
    (id, successCB, failedCB) => async (dispatch) => {
        try {
            const { data } = await API.get(`/v1/messages/${id}`)
            dispatch({
                type: FETCH_MESSAGE_BY,
                payload: data,
            });
            return successCB && successCB(data)
        } catch (error) {
            return failedCB && failedCB(error)
        };
    };

export const fetchMessages =
    (successCB, failedCB) => async (dispatch) => {
        try {
            const { data } = await API.get(`/v1/messages`)
            dispatch({
                type: FETCH_MESSAGE,
                payload: data,
            });
            return successCB && successCB(data)
        } catch (error) {
            return failedCB && failedCB(error)
        };
    };


export const clearMessage =
    () => async (dispatch) => {
        try {
            dispatch({ type: CLEAR_MESSAGE });
        } catch (error) {
            console.log("error signin", error);
        }
    };