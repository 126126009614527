import {
  START_LOADING,
  END_LOADING,
  FETCH_PAGE,
  UPDATE,
  FETCH_BY,
  DELETE,
  FETCH_BY_SEARCH,
  FETCH_ALL_PAGE,
  FETCHING_API,
} from "../../constants/actionTypes";

import axios from "axios";
const API = axios.create({ baseURL: process.env.REACT_APP_HOST });


export const filterElasticSearch = (isPopular, priceOrder) => async (dispatch) => {
  try {
    console.log("isPopular===", isPopular)
    const { data } = await API.get(`/v1/customer/landing-page/search/elasticsearch?isPopular=${isPopular}&price=${priceOrder}`);
    dispatch({ type: FETCH_BY_SEARCH, payload: data });
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAllLandingPage = () => async (dispatch) => {
  try {
    dispatch({ type: FETCHING_API, payload: true });
    const { data } = await API.get(`/v1/customer/landing-page`);
    dispatch({ type: FETCH_ALL_PAGE, payload: data });
    dispatch({ type: FETCHING_API, payload: false });
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getLandingPage = () => async (dispatch) => {
  try {
    dispatch({ type: FETCHING_API, payload: true });
    const { data } = await API.get(`/v1/customer/landing-page`);
    dispatch({ type: FETCH_PAGE, payload: data });
    dispatch({ type: FETCHING_API, payload: false });
  } catch (error) {
    console.log(error);
    return error;
  }
};


export const getBySearchLandingPage = (searchQuery) => async (dispatch) => {
  try {
    const { data } = await API.get(`/v1/customer//landing-page/search?searchQuery=${searchQuery?.search}`);
    dispatch({ type: FETCH_BY_SEARCH, payload: data });
  } catch (error) {
    console.log(error);
    return error;
  }
};


export const showDetailBooking =
  (currentId, successCB, failedCB) => async (dispatch) => {
    API.get(`/admin/booking/${currentId}`)
      .then((response) => {
        const resAPI = response.data;
        dispatch({ type: FETCH_BY, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const confirmBooking =
  (currentId, formData, successCB, failedCB) => async (dispatch) => {
    const data = new FormData();
    data.append("id", formData._id);
    API.put(`/admin/booking/${currentId}/confirmation`, data)
      .then((response) => {
        const resAPI = response.data;
        console.log("resAPI", resAPI);
        dispatch({ type: UPDATE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const rejectBooking =
  (currentId, formData, successCB, failedCB) => async (dispatch) => {
    const data = new FormData();
    data.append("id", formData._id);
    API.put(`/admin/booking/${currentId}/reject`, data)
      .then((response) => {
        const resAPI = response.data;
        console.log("resAPI", resAPI);
        dispatch({ type: UPDATE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const delBooking = (id, successCB, failedCB) => async (dispatch) => {
  API.delete(`/admin/item/${id}/delete`)
    .then((response) => {
      const resAPI = response.data;
      dispatch({ type: DELETE, payload: resAPI });
      return successCB && successCB(resAPI);
    })
    .catch((err) => {

      return failedCB && failedCB(err);
    });
};
