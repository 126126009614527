import Chat from 'components/organisms/Chat'
import React from 'react'


const ChatPage = () => {

    return (
        <>
            <Chat />
        </>
    )
}

export default ChatPage