import env from "configs/vars";
import {
  CREATE,
  FETCH_ALL,
  UPDATE,
  FETCH_BY,
  DELETE,
  LIKE,
  COMMENT,
  FETCH_ALL_ITEM,
  FETCH_BY_ITEM,
} from "../../constants/actionTypes";

import axios from "axios";
const API = axios.create({ baseURL: env.reactAppHost });

export const likeItem = (idUser, id,) => async (dispatch) => {
  try {
    const { data } = await API.put(`/admin/item/${id}/likeItem`, idUser);
    dispatch({ type: LIKE, payload: data });
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const commentItem = (payload, id) => async (dispatch) => {
  console.log('id', id);
  console.log('payload', payload);
  try {
    const { data } = await API.post(`/admin/item/${id}/commentItem`, payload);
    dispatch({ type: COMMENT, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const createItem =
  (formData, successCB, failedCB) => async (dispatch) => {
    const data = new FormData();
    data.append("categoryId", formData.categoryId);
    data.append("title", formData.title);
    data.append("price", formData.price);
    data.append("description", formData.description);
    data.append("unit", "Kg");
    data.append("file", formData.file);
    API.post("/admin/item", data)
      .then((response) => {
        const resAPI = response.data;
        dispatch({ type: CREATE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const getAllItem = () => async (dispatch) => {
  try {
    const { data } = await API.get("/admin/item");
    dispatch({ type: FETCH_ALL_ITEM, payload: data });
  } catch (error) {
    throw error
  }
};

export const getItem = (currentId,) => async (dispatch) => {
  try {
    console.log('getItem called');
    const { data } = await API.get(`/admin/itemById/${currentId}`);
    dispatch({ type: FETCH_BY_ITEM, payload: data });
  } catch (error) {
    throw error
  }
};

export const getItemImage =
  (currentId, successCB, failedCB) => async (dispatch) => {
    API.get(`/admin/item/show-image/${currentId}`)
      .then((response) => {
        const resAPI = response.data.item.imageId;
        dispatch({ type: FETCH_BY, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const updateItem =
  (currentId, formData, successCB, failedCB) => async (dispatch) => {
    const data = new FormData();
    data.append("title", formData.title);
    data.append("price", formData.price);
    data.append("city", formData.city);
    data.append("categoryId", formData.categoryId);
    data.append("file", formData.file);
    data.append("description", formData.description);
    API.put(`/admin/item/${currentId}`, data)
      .then((response) => {
        const resAPI = response.data;
        dispatch({ type: UPDATE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const delItem = (id, successCB, failedCB) => async (dispatch) => {
  API.delete(`/admin/item/${id}/delete`)
    .then((response) => {
      const resAPI = response.data;
      dispatch({ type: DELETE, payload: resAPI });
      return successCB && successCB(resAPI);
    })
    .catch((err) => {

      return failedCB && failedCB(err);
    });
};

export const viewDetailItem =
  (currentId, successCB, failedCB) => async (dispatch) => {
    API.get(`/admin/item/show-detail-item/${currentId}`)
      .then((response) => {
        const resAPI = response.data;
        dispatch({ type: FETCH_ALL, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const createFeatureItem =
  (currentId, formData, successCB, failedCB) => async (dispatch) => {
    const data = new FormData();
    data.append("itemId", currentId);
    data.append("name", formData.name);
    data.append("qty", formData.qty);
    data.append("file", formData.file);
    API.post("/admin/item/add/feature", data)
      .then((response) => {
        const resAPI = response.data;
        console.log("resAPI response", resAPI);
        dispatch({ type: CREATE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const showEditFeature =
  (showEditFeature, successCB, failedCB) => async (dispatch) => {
    API.get(`/admin/item/showEditFeature/${showEditFeature}`)
      .then((response) => {
        const resAPI = response.data;
        dispatch({ type: FETCH_BY, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const updateFeatureItem =
  (formData, successCB, failedCB) => async (dispatch) => {
    const data = new FormData();
    data.append("id", formData._id);
    data.append("itemId", formData.itemId);
    data.append("name", formData.name);
    data.append("qty", formData.qty);
    data.append("file", formData.file);
    API.put("/admin/item/update/feature", data)
      .then((response) => {
        const resAPI = response.data;
        console.log("resAPI updateDetailItem ", resAPI);
        dispatch({ type: UPDATE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const delFeatureItem =
  (itemId, id, successCB, failedCB) => async (dispatch) => {
    API.delete(`/admin/item/${itemId}/feature/${id}`)
      .then((response) => {
        const resAPI = response.data;
        dispatch({ type: DELETE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const createActivityItem =
  (currentId, formData, successCB, failedCB) => async (dispatch) => {
    const data = new FormData();
    data.append("itemId", currentId);
    data.append("name", formData.name);
    data.append("type", formData.type);
    data.append("file", formData.file);
    API.post("/admin/item/add/activity", data)
      .then((response) => {
        const resAPI = response.data;
        console.log("resAPI response", resAPI);
        dispatch({ type: CREATE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const showEditActivity =
  (showEditActivity, successCB, failedCB) => async (dispatch) => {
    API.get(`/admin/item/showEditActivity/${showEditActivity}`)
      .then((response) => {
        const resAPI = response.data;
        dispatch({ type: FETCH_BY, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const updateActivityItem =
  (formData, successCB, failedCB) => async (dispatch) => {
    const data = new FormData();
    data.append("id", formData._id);
    data.append("itemId", formData.itemId);
    data.append("name", formData.name);
    data.append("type", formData.type);
    data.append("file", formData.file);
    API.put("/admin/item/update/activity", data)
      .then((response) => {
        const resAPI = response.data;
        console.log("resAPI updateDetailItem ", resAPI);
        dispatch({ type: UPDATE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const delActivityItem =
  (itemId, id, successCB, failedCB) => async (dispatch) => {
    API.delete(`/admin/item/${itemId}/activity/${id}`)
      .then((response) => {
        const resAPI = response.data;
        dispatch({ type: DELETE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };
