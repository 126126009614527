import React, { useEffect, useState } from "react";
import {
  Table, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, NativeSelect,
  Container, Typography, Box, Grid, TextField, CssBaseline, Button, Avatar, Card,
} from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastProperties } from "utils/toastProperties";
import convertToRupiah from "utils/formatCurrency";
import { getUserByRole } from "store/actions/user";
import { confirmBooking, rejectBooking, showDetailBooking, } from "store/actions/booking";
import { Loading } from "components/atoms";
import { isEmpty } from "utils/string";
import PencilLoader from "components/atoms/PencilLoader";
import env from "configs/vars";

const theme = createTheme();

const statusOrder = [{ id: 1, name: 'Proses' }, { id: 2, name: 'Kirim' }, { id: 3, name: 'Selesai' }];

export default function BookingDetail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { currentId } = params;
  const { dataBookingReducer } = useSelector((state) => state.bookingReducer);
  const { dataUser } = useSelector((state) => state.userReducer);
  const [dataSender, setDataSender] = useState([]);
  const initialState = { sender_id: "", id: "", payments_status: "", user_id: "" };
  const [form, setForm] = useState(initialState);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token')));
  const userId = user?.user?.id;

  useEffect(() => {
    if (currentId) {
      dispatch(showDetailBooking(userId, "", 1, currentId));
      dispatch(getUserByRole('driver'));
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentId) {
      if (dataBookingReducer) {
        setForm({
          invoice: dataBookingReducer?.data.invoice,
          items: dataBookingReducer?.data.items,
          total: dataBookingReducer?.data.total,
          payments_bank_from: dataBookingReducer?.data.payments_bank_from,
          sender_id: dataBookingReducer?.data.sender_id,
          id: dataBookingReducer?.data.id,
          payments_status: dataBookingReducer?.data.payments_status,
          user_id: dataBookingReducer?.data.user_id,
        });
      }
    }
  }, [dataBookingReducer]);

  useEffect(() => {
    setDataSender(dataUser?.account);
  }, [dataUser]);

  // console.log("dataUser.account===>>", dataUser.account)

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const type = e.target.value;

    if (type === "Confirm") {
      dispatch(
        confirmBooking(
          {
            id: form.id,
            payments_status: form.payments_status,
            sender_id: form.sender_id,
            user_id: form.user_id
          },
          (res) => {
            toast.success(res?.message, toastProperties);
            history.push(`/adminwol/viewBooking`);
          },
          (error) => {
            toast.error(
              error?.response?.message || "Something went wrong",
              toastProperties
            );
          }
        )
      );
    } else {
      dispatch(
        rejectBooking(
          currentId,
          form,
          (res) => {
            toast.success(res?.message, toastProperties);
            history.push(`/adminwol/viewBooking`);
          },
          (error) => {
            toast.error(
              error?.message || "Something went wrong",
              toastProperties
            );
          }
        )
      );
    }
  }; // end handleSubmit

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <ReceiptIcon />
            </Avatar>
            <Typography variant="title">
              Order Information
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
              autoComplete="on"
            >

              {
                isEmpty(dataBookingReducer) ? (
                  <PencilLoader />
                ) : (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Invoice</TableCell>
                          <TableCell>  <Typography>{form?.invoice}</Typography></TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Item</TableCell>
                          <TableCell><Typography>
                            {form?.items?.map((item, index) => (
                              <span key={index}>
                                {item.title}
                                {index < form?.items.length - 1 && ', '}
                              </span>
                            ))}
                          </Typography></TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Price</TableCell>
                          <TableCell> <Typography>{convertToRupiah(form?.total)}</Typography></TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Payment</TableCell>
                          <TableCell> <Typography>{form?.payments_bank_from}</Typography></TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Status</TableCell>
                          <TableCell>
                            <FormControl size="small" fullWidth>
                              <NativeSelect
                                required
                                name="payments_status"
                                onChange={onChange}
                                value={form?.payments_status}
                              >
                                {statusOrder &&
                                  statusOrder.map((e) => {
                                    return <option value={e.name}>{e.name}</option>;
                                  })}
                              </NativeSelect>
                            </FormControl>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Kurir</TableCell>
                          <TableCell>
                            <FormControl size="small" fullWidth>
                              <NativeSelect
                                required
                                name="sender_id"
                                labelId="demo-simple-select-label"
                                onChange={onChange}
                                value={form.sender_id}
                              >
                                {dataSender && dataSender.length > 0 &&
                                  dataSender?.map((e) => {
                                    return (
                                      <option key={e.users_id} value={e.users_id}>
                                        {e.name}
                                      </option>
                                    );
                                  })}
                              </NativeSelect>
                            </FormControl>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={2}>
                            <img
                              className="img rounded mx-auto d-block"
                              style={{
                                height: 300,
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                              src={`${env.bucketItem}/order/${dataBookingReducer?.data?.payments_proof_payment}`}
                            />

                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                )

              }

              <Grid container spacing={2} sx={{ mt: 1 }}>
                {/* <Typography>
                  {form?.bankId?.name} {form?.bankId?.nameBank}
                </Typography> */}
                <TextField
                  onChange={onChange}
                  value={form?.id}
                  name="id"
                  hidden
                  required
                  fullWidth
                  style={{ display: 'none' }}
                />
                <Grid item xs={6} sm={6}>
                  <Button
                    onClick={handleSubmit}
                    value="Confirm"
                    type="submit"
                    fullWidth
                    variant="contained"
                  >
                    Confirmation
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button
                    onClick={handleSubmit}
                    value="Reject"
                    type="submit"
                    fullWidth
                    variant="outlined"
                  >
                    Reject
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Box>
      </Container >
    </ThemeProvider >
  );
}
