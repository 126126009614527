import { CHECKOUT_BOOKING } from "../../constants/actionTypes";
import axios from "configs/axios";

export const checkoutBooking = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_BOOKING,
    payload: payload,
  });
};

export const submitBooking = (payload) => () => {
  const formData = new FormData();

  formData.append('userId', payload.userId);
  formData.append('email', payload.email);
  formData.append('cartItems', JSON.stringify(payload.cartItems));
  formData.append('totalOrder', payload.totalOrder);
  formData.append('totalProduct', payload.totalProduct);
  formData.append('bankFrom', payload.bankFrom);
  formData.append('selectedFile', payload.selectedFile);

  return axios.post(`/customer/booking-page`, formData, {
  });
};
